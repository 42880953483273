import React from "react"
import Section from "./Section"
import { useStaticQuery, graphql } from "gatsby"
import SectionOverview from "./SectionOverview"

export default function SectionBanner(props) {
  const data = useStaticQuery(graphql`
    query {
      sectionHeroImages: allFile(
        filter: { sourceInstanceName: { eq: "images" } }
      ) {
        edges {
          node {
            relativePath
            publicURL
          }
        }
      }
    }
  `);

  function handleBackgroundImport(d, path) {
    let srcPublic = "";

    d.sectionHeroImages.edges.forEach(image => {
      if (image.node.relativePath === path) {
        srcPublic = image.node.publicURL
      }
    });

    return srcPublic;
  }

  return (
    <Section
      className="section-banner"
      spacing={0}
      constrain
      bgImage={handleBackgroundImport(data, props.bgImage)}
      id={props.id}
      bgTextShade={props.bgTextShade}
    >
      <div className="section-banner-details">
        <SectionOverview
          title={props.parentTitle}
          align={"center"}
          pipeColor={props.pipeColor}
        >
          {props.title ? <h1>{props.title}</h1> : null}
          {props.children ? <>{props.children}</> : null}
        </SectionOverview>

        <p className="section-banner-description">
          {props.description}
        </p>
      </div>
    </Section>
  )
}
