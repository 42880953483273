import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout";
import SectionBanner from "../components/SectionBanner"
import SectionOverview from "../components/SectionOverview"
import Section from "../components/Section"
import Seo from "../components/Seo"

export default function BlogPostTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    
    <Layout id="blog">
      <Seo
        title={frontmatter.title}
        description={frontmatter.snippet}
        relPath={frontmatter.slug}
      />
      <div className="page--blog-article">
        <SectionBanner
            id={"hero"}
            bgImage={frontmatter.banner_image}
            bgTextShade={true}
        >
          <p className="decorative-hero">Blog and News</p>
       </SectionBanner>
        <Section constrain indent spacing={30}>
          <SectionOverview align={'left'} title={''} divider={true}>
            <h1 className="blog-title">{frontmatter.title}</h1>
          </SectionOverview>
          <p className="blog-date">{frontmatter.date}</p>
          
          <div
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Section>

      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        author
        snippet
        banner_image
      }
    }
  }
`